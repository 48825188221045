import axios from '@/utils/request';

export async function fetchConfig() {
    return axios.get('fetchConfig');
}

export async function lineLogin(payload) {
    return axios.post('lineWebLogin', payload);
}

export async function userAuthorization(payload) {
    return axios.post('user/authorization', payload);
}

export async function smsRegister(mobile) {
    return axios.post(`sms/register/${mobile}`);
}

export async function mobileLogin(payload) {
    return axios.post("mobileLogin", payload);
}