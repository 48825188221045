<template>
  <div class="login">
    <div class="mobile-verify" v-show="mobileVerify">
      <van-image width="100" height="100" round fit="cover" :src="require('@/assets/logo.png')" />

      <van-field v-model="mobile" type="digit" label="+66" style="margin-top: 50px" :placeholder="$t('请输入手机号')"
        maxlength="10" rows="1" autosize />

      <div class="verify-code">
        <van-field v-model="smsCode" type="digit" style="flex: 1" :placeholder="$t('请输入验证码')" rows="1" maxlength="6"
          autosize />

        <span class="get-verify-code" @click="getVerifyCode()">
          {{ verifyCodeMsg }}
        </span>
      </div>

      <van-button @click="doSubmit" color="#ee502f" round :loading="submitLoading" :disabled="submitLoading"
        style="width: 200px; margin: 20px 0px">
        {{ $t("登录") }}
      </van-button>
    </div>

    <div class="login-progress" v-show="!mobileVerify">
      <van-image width="130" height="130" round fit="cover" :src="require('@/assets/logo.png')" />
      <van-loading color="#40c057" />
      <span v-if="loadingMessage">{{ loadingMessage }}</span>
    </div>
  </div>
</template>

<script>
import { Loading, Image as VanImage, Toast, Popup, Field, Button } from "vant";
import { PageRouter, toAuthorizationLogin } from "@/utils/pages";
import {
  fetchConfig,
  userAuthorization,
  smsRegister,
  mobileLogin,
} from "./service";

import storage, { setChatWoot, getAuthPage } from "@/utils/storage";
import { STORAGE_KEYS, LINE, FACEBOOK, CONST } from "@/utils/constants";
import { fetchUserInfo } from "@/service/api";
import {
  setAccessToken,
  setConfig,
  getAccessTokenWithValidStatus,
  isLogin,
} from "@/utils/token";
import { initCartDB } from "@/utils/cart.js";
import { parseUrl } from "@/utils/utils";
import { shopifyTokenExpired } from "@/utils/token";
import { trackCompleteRegistration } from "@/utils/statistic";

export default {
  components: {
    [Loading.name]: Loading,
    [VanImage.name]: VanImage,
    [Popup.name]: Popup,
    [Field.name]: Field,
    [Button.name]: Button,
  },

  data() {
    return {
      mobile: null,
      submitLoading: false,
      mobileVerify: false,
      countDown: 60,
      verifyCodeMsg: this.$t("获取验证码"),
      user: null,
      loginChannel: null,
      smsCode: null,
      loadingMessage: "",
    };
  },

  mounted() {
    this.loadingText();
    const { code } = this.$route.query;
    const currentUrl = window.location.toString();

    //facebook login
    if (currentUrl.includes("#")) {
      const index = currentUrl.indexOf("#");
      const query = currentUrl.slice(index + 1);
      const { queryMap } = parseUrl(query);
      const { access_token } = queryMap;

      if (access_token) {
        this.userAuthorization(CONST.LOGIN_TYPE.FACEBOOK, { access_token });
        return;
      }
    }

    //line login
    if (code)
      this.userAuthorization(CONST.LOGIN_TYPE.LINE, {
        code,
        redirectUri: LINE.LOGIN_CALLBACK,
      });
    else this.fetchConfig();
  },
  methods: {
    loadingText() {
      const { redirect } = this.$route.query;
      switch (redirect) {
        case PageRouter.ali_product_url:
          this.loadingMessage = this.$t("正在查找商品，请等待一会");
          break;
      }
    },
    async getVerifyCode() {
      if (this.countDown > 0 && this.countDown < 60) return;

      const mobile = this.mobile;
      if (!mobile) {
        Toast(this.$t("请输入手机号"));
        return;
      }

      if (mobile.length != 10 || !mobile.startsWith("0")) {
        Toast(this.$t("电话号码必须以0开头10位数"));
        return;
      }

      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });

      const res = await smsRegister(`+66${mobile}`);
      const { success } = res;

      Toast.clear();
      if (success) {
        trackCompleteRegistration();
        const timer = setInterval(() => {
          this.countDown--;
          if (this.countDown <= 0) {
            this.countDown = 60;
            this.verifyCodeMsg = this.$t("获取验证码");
            clearInterval(timer);
          } else
            this.verifyCodeMsg = this.$t("counts后重新发送", {
              count: this.countDown,
            });
        }, 1000);
      }
    },
    async doSubmit() {
      const mobile = this.mobile;
      if (!mobile) {
        Toast(this.$t("请输入手机号"));
        return;
      }

      if (mobile.length != 10 || !mobile.startsWith("0")) {
        Toast(this.$t("电话号码必须以0开头10位数"));
        return;
      }

      const smsCode = this.smsCode;
      if (!smsCode) {
        Toast(this.$t("请输入验证码"));
        return;
      }

      const payload = {
        loginChannel: this.loginChannel,
        smsCode,
        mobile: `+66${mobile}`,
        createShopifyCustomer: true,
        user: this.user,
      };

      this.submitLoading = true;
      const res = await mobileLogin(payload);

      const { success, data } = res;
      this.submitLoading = false;
      if (success) {
        if (data && data.value) {
          setAccessToken(data);
          this.fetchUserInfo();
          return;
        }
      } else Toast.fail(this.$t(res.message));
    },
    async userAuthorization(loginType, params) {
      let payload = { createShopifyCustomer: true };

      switch (loginType) {
        case CONST.LOGIN_TYPE.FACEBOOK:
          payload = {
            ...payload,
            loginChannel: FACEBOOK.LOGIN_CHANNEL,
            ...params,
          };
          break;
        case CONST.LOGIN_TYPE.LINE:
          payload = {
            ...payload,
            loginChannel: LINE.LOGIN_CHANNEL,
            ...params,
          };
          break;
      }

      const res = await userAuthorization(payload);
      const { success, data } = res;

      if (success) {
        const { value } = data;
        if (value) {
          setAccessToken(data);
          this.fetchUserInfo();
        } else {
          this.loginChannel = payload.loginChannel;
          this.user = data.user;
          this.mobileVerify = true;
        }
      } else Toast.fail(res.message);
    },
    async fetchConfig() {
      const res = await fetchConfig();
      const { success, data } = res;
      if (success) {
        setConfig(data);
        this.needRefreshToken();
      }
    },
    needRefreshToken() {
      //shopify token过期处理
      if (shopifyTokenExpired()) {
        Toast(this.$t("Please login"));
        setTimeout(() => {
          toAuthorizationLogin();
          this.$store.commit("setTokenExpired", false);
        }, 500);
        return;
      }

      const { isTokenExpired } = this.$store.state;
      if (isTokenExpired) {
        Toast(this.$t("Please login"));
        setTimeout(() => {
          toAuthorizationLogin();
          this.$store.commit("setTokenExpired", false);
        }, 500);
      } else {
        let { token, isValid } = getAccessTokenWithValidStatus();
        if (token && !isValid) toAuthorizationLogin();
        else this.toTargetPage();
      }
    },
    toTargetPage() {
      const authPage = getAuthPage();
      if (authPage) {
        storage.remove(STORAGE_KEYS.authPage);

        Toast(this.$t("You have successfully logged in"));
        setTimeout(() => {
          if (authPage.name) {
            this.$router.replace(authPage);
          } else this.$router.replace({ name: "Home" });
        }, 1000);
        return;
      }

      // 兼容WhatsApp发送url消息urlencode
      const currentUrl = window.location.toString();
      const { queryMap: query } = parseUrl(decodeURIComponent(currentUrl))

      const { redirect, chatChannel, chatConversationId, chatUserId } = query;

      //chatwoot info store
      if (chatChannel && chatConversationId && chatUserId) {
        setChatWoot({ chatChannel, chatConversationId, chatUserId });
      }
      const queryParams = { ...query, isRedirect: 1 };
      delete queryParams.redirect;

      let target = redirect ? redirect : "home";
      switch (target) {
        case PageRouter.product:
          this.$router.replace({
            name: "Product",
            query: { ...queryParams, back: false },
          });
          break;
        case PageRouter.shopee_search:
          this.$router.replace({
            name: "shopeeSearch",
            query: queryParams,
          });
          break;
        case PageRouter.ali_product_url:
          this.$router.replace({
            name: "aliproduct",
            query: queryParams,
          });
          break;
        case PageRouter.order:
          if (isLogin()) {
            const { type } = query;
            let orderType = 1;
            switch (type) {
              case "to-be-confirmed":
                orderType = 1;
                break;
              case "to-pay":
                orderType = 2;
                break;
              case "to-ship":
                orderType = 3;
                break;
              case "to-receive":
                orderType = 4;
                break;
              case "cancelled":
                orderType = 5;
                break;
              case "completed":
                orderType = 6;
                break;
            }
            // console.log("orderType",orderType);
            this.$router.replace({
              name: "orders",
              query: { ...queryParams, orderType },
            });
          } else {
            Toast(this.$t("Please login"));
            setTimeout(() => {
              toAuthorizationLogin();
            }, 500);
          }
          break;
        default:
          this.$router.replace({
            name: "Home",
            // query: queryParams,
          });
          break;
      }
    },
    async fetchUserInfo() {
      const res = await fetchUserInfo();

      if (res && res.success) {
        storage.setObj(STORAGE_KEYS.userInfo, res.data);
        this.userInfo = res.data;
        initCartDB();
        this.toTargetPage();
      }
    },
  },
};
</script>
<style lang="less" scoped>
@primaryColor: #ee502f;

.login {
  width: 100%;
  height: 100%;
  text-align: center;
}

.login-progress {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
}

.allow {
  flex: 1;
  padding: 12px 0px;
  background-color: #40c057;
  text-align: center;
  color: white;
  border-radius: 5px;
}

.cancel {
  flex: 1;
  background-color: #ededed;
  border-radius: 5px;
  margin-right: 10px;
  text-align: center;
  padding: 10px 0px;
}

.mobile-verify {
  padding-top: 50px;
  padding-left: 30px;
  padding-right: 30px;
}

.verify-code {
  display: flex;
  align-items: center;
  background-color: white;
}

.get-verify-code {
  color: @primaryColor;
  display: inline-block;
  padding: 0px 10px;
  font-size: 0.9rem;
}

:deep(.van-field__label) {
  width: auto;
}
</style>